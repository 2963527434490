'use client'
import React, { useEffect, useState } from 'react'
import CityDateFilters from '../CityDateFilters';
import CityPage from '../citylayout/page';

const CityComponent = () => {
    
    const defaultLat = 43.653226; // Latitude for Toronto
    const defaultLon = -79.3831843; // Longitude for Toronto
    const [city, setCity] = useState('TORONTO');
    const [lat, setLat] = useState<number>(defaultLat);
    const [lon, setLon] = useState<number>(defaultLon);
    const [dateRange, setDateRange] = useState('Next 7 Days');

    useEffect(() => {
        const savedCity = localStorage.getItem('city');
        const savedLat = localStorage.getItem('lat');
        const savedLon = localStorage.getItem('lon');
    
        if (savedCity && savedLat && savedLon) {
          setCity(savedCity);
          setLat(parseFloat(savedLat));
          setLon(parseFloat(savedLon));
        }
    
      }, [lat, lon, city]);
    


    return (
    <>
    <CityDateFilters dateRange={dateRange} setLat={setLat} setLon={setLon} setDateRange={setDateRange} setCity={setCity} />
    <CityPage lat={lat} lon={lon} dateRange={dateRange} />
    </>
  )
}

export default CityComponent