'use client'
import axios from 'axios';
import { NextPage } from 'next';
import Image from 'next/image';
import weekend1 from "../../../public/weekend1.png";
import { Oswald, Roboto, Fredoka } from 'next/font/google';
import { useRouter } from "next/navigation";
import { FiArrowRight } from "react-icons/fi";
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import SkeletonCard from '../SkeletonCard/SkeletonCard';

const fredoka = Fredoka({ subsets: ['latin'] });
const oswalds = Oswald({ subsets: ['latin'] });
const roboto = Roboto({ weight: ['300'], subsets: ['latin'] });

interface Weekend {
  id: number;
  name: string;
  occurs_at: string;
  venue: {
    name: string;
  };
}

interface imageInfo {
  eventId: number;
  image_url: string;
}

interface WeekendProps {
  lat: any;
  lon: any;
}
let url: any;
if (typeof window !== "undefined") {
  url = localStorage.getItem("Frontend_API");
  // console.log(url);
}

let domain_url: any;

if (typeof window !== "undefined") {
    domain_url = localStorage.getItem("domain_url");
    // console.log(domain_url);
}
export const Weekend: NextPage<WeekendProps> = ({ lat, lon }) => {
  const [weekend, setWeekend] = useState<Weekend[]>([]);
  const [imageInfo, setImageInfo] = useState<imageInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [weekendLoading, setWeekendLoading] = useState(false);

  useEffect(() => {
    const fetchEvents = async (limit:number) => {
      setWeekendLoading(true);
      try {
        const message = `GET ${domain_url}/v9/events?order_by=events.popularity_score%20DESC;lat=${lat};lon=${lon};within=20;per_page=${limit}`;
        console.log(message);

        const res = await fetch(`${url}/api/signature`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(message),
        });

        if (!res.ok) {
          setWeekendLoading(false);
          throw new Error('Failed to fetch signature');
        }

        const data1 = await res.json();
        const requestBody = {
          signature: data1.msg,
          lat,
          lon,
          limit
        };

        const response = await fetch(`${url}/api/weekendproxy`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          setWeekendLoading(false);
          throw new Error('Failed to fetch events');
        }

        const data = await response.json();
        console.log('this is weekend data: ', data);

        setWeekend(data.data);
        setImageInfo(data.imageInfo);
        setWeekendLoading(false);
      } catch (error: any) {
        setWeekendLoading(false);
        setError(error.message);
      } finally {
        setWeekendLoading(false);
        setLoading(false);
      }
    };

    if (lat && lon) {
      fetchEvents(10);
    }
  }, [lat, lon]);

  // if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!weekendLoading && weekend.length === 0) return null;

  const filteredWeekend = weekend.filter((performer: any) => !performer.name.includes("Rescheduled")).slice(0, 15);
  const filteredImageInfo = filteredWeekend.slice(0, 15).map(performer => {
    const matchedInfo = imageInfo.find(info => info?.eventId === performer.id);
    return matchedInfo ? matchedInfo : { eventId: performer.id, image_url: weekend1 };
  });

  return (
    <div className="w-full flex justify-center">
      {loading &&
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 bg-white w-screen h-screen">
          <div className='w-full h-full absolute flex items-center justify-center text-black'><LoadingAnimation /></div>
        </div>
      }
      <div className="w-full flex flex-col gap-2 justify-start max-[976px]:px-2 min-[1200px]:w-[1170px] min-[922px]:w-[970px] min-md:w-[750px] max-md:px-[15px] max-md:mx-auto">
        <div className="flex justify-between">
          <h1 className={`text-stone-900 md:text-3xl text-2xl font-medium font-oswald uppercase leading-10 ${fredoka.className}`}>
            POPULAR THIS WEEKEND
          </h1>
          <div className="flex items-center">
          <Link href={`/popularthisweekend?lat=${lat}&lon=${lon}`}>
              <button className="flex items-center bg-white font-medium text-black px-4 py-2 rounded-md transition-colors duration-300 ease-in-out hover:bg-gray-300 sm:w-auto sm:px-6 sm:py-3" onClick={() => setLoading(true)}>
                <span className="mr-2 hidden md:inline-block">See more events</span>
                <FiArrowRight className="w-4 h-4" />
              </button>
            </Link>
          </div>
        </div>
        {
          weekendLoading ? (
          <Carousel opts={{ align: "center" }} className="w-full flex justify-center items-center">
          <CarouselContent>
          {[...Array(4)].map((_, index) => (
                <CarouselItem key={index} className="sm:basis-1/2 md:basis-1/3 lg:basis-1/4">
                  <SkeletonCard />
                </CarouselItem>
              ))}
          </CarouselContent>
        </Carousel>
          ) : (
            <>
              <Carousel opts={{ align: "start" }} className="w-full">
                <CarouselContent className='flex justify-start items-start'>
                  {filteredWeekend.map((performer: any, index) => (
                    <CarouselItem key={performer?.id} className="sm:basis-1/3 lg:basis-1/4">
                      <Link href={`/viewtickets1/${performer?.id}`}>
                        <div className={`flex flex-col gap-[8px] ${roboto.className} transition duration-300 hover:shadow-sm hover:scale-[1.02] cursor-pointer`} onClick={() => setLoading(true)}>
                          <div className={`flex flex-col gap-[8px] ${roboto.className}`}>
                            <Image src={filteredImageInfo[index]?.eventId === performer?.id ? filteredImageInfo[index].image_url : weekend1} alt="event" width={300} height={100} className="rounded-lg shrink-0 object-cover w-full sm:w-[300px] h-[150px]" />
                            <div className={`text-stone-500 text-base font-semibold leading-tight ${fredoka.className}`}>{performer?.name}</div>
                            <div className="text-stone-500 text-base font-normal leading-tight">{formatDate(performer?.occurs_at)}</div>
                            <div className="text-stone-500 text-base font-normal leading-tight">{performer?.venue?.name}</div>
                          </div>
                        </div>
                      </Link>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                {
                  weekend?.length > 4 ? (
                    <>
                      <CarouselPrevious className='hidden sm:block p-6 py-2 pl-2 rounded-full cursor-pointer' />
                      <CarouselNext className='hidden sm:block p-6 py-2 pl-2 rounded-full cursor-pointer' />
                    </>
                  ) : weekend?.length === 4 && (
                    <div className='hidden md:block lg:hidden'>
                      <CarouselPrevious className='hidden sm:block p-6 py-2 pl-2 rounded-full cursor-pointer' />
                      <CarouselNext className='hidden sm:block p-6 py-2 pl-2 rounded-full cursor-pointer' />
                    </div>
                  )
                }
              </Carousel>
            </>
          )
        }
    </div>
    </div >
  );
};

function formatDate(inputDate:any) {
  // Parsing the date as UTC to avoid timezone affecting the output
  const date = new Date(inputDate);
  return date.toLocaleDateString('en-US', {
      timeZone: 'UTC', 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long',
      day: 'numeric' 
  });
}

export default Weekend;
