// components/citylayout/page.tsx
import React from 'react';
import { TopTeams } from '../Topteams/page';
import Weekend from '../Weekend/page';
import Broadway from '../Broadway/page';
import Family from '../Family/page';
import { TopArtist } from '../Topartist/page';

interface CityPageProps {
  lat: Number;
  lon: Number;
  dateRange: string;
}

const CityPage: React.FC<CityPageProps> = ({ lat, lon, dateRange }) => {
  return (
    <div className='flex flex-col gap-4 md:gap-6 lg:gap-8'>
    
      <TopArtist lat={lat} lon={lon} dateRange={dateRange} />
      
      <TopTeams lat={lat} lon={lon} dateRange={dateRange} />
      
      <Weekend lat={lat} lon={lon} dateRange={dateRange} />
     
      <Broadway lat={lat} lon={lon} dateRange={dateRange} />
     
      <Family lat={lat} lon={lon} dateRange={dateRange} />
    </div>
  );
};

export default CityPage;
