'use client';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Command, CommandInput, CommandEmpty, CommandGroup, CommandItem } from "@/components/ui/command";
import { ChevronDown } from 'lucide-react';

interface CitySearchBarProps {
  onSelect: (city: string, lat: number, lon: number) => void;
}

const defaultCities = [
  { name: 'Toronto', description: 'Toronto, CA' },
  { name: 'New York', description: 'New York, USA' },
  { name: 'Los Angeles', description: 'Los Angeles, USA' },
  { name: 'Montreal', description: 'Montreal, CA' },
  { name: 'Vancouver', description: 'Vancouver, CA' },
  { name: 'Ottawa', description: 'Ottawa, CA'},
  { name: 'Edmonton', description: 'Edmonton, CA'}
];

const CitySearchBar: React.FC<CitySearchBarProps> = ({ onSelect }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedCity, setSelectedCity] = useState(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('city') || 'TORONTO';
    }
    return 'TORONTO';
  });
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [open, setOpen] = useState(false);

  // Handle input changes
  const handleInputChange = async (value: string) => {
    setInputValue(value); // Update input value directly

    if (!value) {
      setSuggestions(defaultCities); // Reset to default cities when input is empty
    } else {
      try {
        const response = await axios.get(`/api/selectCity`, {
          params: { query: value },
        });
        setSuggestions(response.data.predictions);
      } catch (error) {
        console.error(error);
        setSuggestions([]); // Fallback to empty array on error
      }
    }
  };

  // Handle city selection
  const handleSelect = async (description: string) => {
    const city = description.split(',')[0].toUpperCase();
    setSelectedCity(city);
    setInputValue(''); // Clear input to reset suggestions
    setSuggestions(defaultCities); // Reset suggestions to default cities
    setOpen(false);

    // Store the selected city in localStorage
    if (typeof window !== 'undefined') {
      localStorage.setItem('city', city);
    }

    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_URL}`,
        {
          params: {
            address: description,
            key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
          },
        }
      );

      const location = response.data.results[0].geometry.location;
      onSelect(city, location.lat, location.lng);
    } catch (error) {
      console.error(error);
    }
  };

  // Reset suggestions when the popover opens
  useEffect(() => {
    if (open && !inputValue) {
      setSuggestions(defaultCities);
    }
  }, [open, inputValue]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          role="combobox"
          aria-expanded={open}
          className="flex items-center text-blue-600 ..."
        >
          {selectedCity}
          <ChevronDown className="ml-2" />
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <Command>
          <CommandInput 
            placeholder="Type here to search a new city" 
            value={inputValue}
            onValueChange={handleInputChange}
          />
          {suggestions.length === 0 && <CommandEmpty>No city found.</CommandEmpty>}
          <CommandGroup>
            {suggestions.map((suggestion) => (
              <CommandItem
                key={suggestion.place_id || suggestion.name}
                onSelect={() => handleSelect(suggestion.description)}
              >
                {suggestion.description || suggestion.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default CitySearchBar;
