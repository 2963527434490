"use client"
import { NextPage } from 'next';
import Image from 'next/image';
import Link from 'next/link';
import { FiArrowRight } from "react-icons/fi";
import { Oswald, Roboto, Fredoka } from 'next/font/google';
import sports from '../../../public/sports.jpg'
const fredoka = Fredoka({ subsets: ['latin'] })
const oswalds = Oswald({ subsets: ['latin'] })
import React,{useState, useEffect} from 'react'
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
const roboto = Roboto({ weight: ['300'], subsets: ['latin'] })

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import SkeletonCard from '../SkeletonCard/SkeletonCard';

interface TopTeams {
  id: number;
  name: string;
  occurs_at: string;
  venue: {
    name: string;
  };

}
interface imageInfo {
  eventId : number;
  image_url : string;
}


interface TopTeamsProps {
  lat: any;
  lon: any;
  dateRange:string;
}

function formatDate(inputDate:any) {
  // Parsing the date as UTC to avoid timezone affecting the output
  const date = new Date(inputDate);
  return date.toLocaleDateString('en-US', {
      timeZone: 'UTC', 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long',
      day: 'numeric' 
  });
}
let url: any;
if (typeof window !== "undefined") {
  url = localStorage.getItem("Frontend_API");
  // console.log(url);
}

let domain_url: any;

if (typeof window !== "undefined") {
    domain_url = localStorage.getItem("domain_url");
    // console.log(domain_url);
}

export const TopTeams: NextPage<TopTeamsProps> = ({ lat, lon, dateRange }) => {
  // Filter performers based on the presence of performer?.venue?.name

  const [topteams, setTopTeams] = useState<TopTeams[]>([]);
  const [imageInfo, setImageInfo] = useState<imageInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [teamsLoading, setTeamsLoading] = useState(false);
  useEffect(() => {
    const fetchEvents = async () => {
      setTeamsLoading(true);
      try {
        const message = `GET ${domain_url}/v9/events?category_id=1&category_tree=true&order_by=events.popularity_score%20DESC;lat=${lat};lon=${lon};within=20;`;
        console.log(message);

        const res = await fetch(`${url}/api/signature`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(message),
        });

        if (!res.ok) {
          setTeamsLoading(false);
          throw new Error('Failed to fetch signature');
        }

        const data1 = await res.json();
        const requestBody = {
          signature: data1.msg,
          lat,
          lon,
          dateRange
        };

        const response = await fetch(`${url}/api/teamsproxy`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          setTeamsLoading(false);
          throw new Error('Failed to fetch events');
        }

        const data = await response.json();
        console.log('this is artist data: ', data);

        setTopTeams(data.data);
        setImageInfo(data.imageInfo);
        setTeamsLoading(false);
      } catch (error: any) {
        setTeamsLoading(false);
        setError(error.message);
      } finally {
        setTeamsLoading(false);
        setLoading(false);
      }
    };

    if (lat && lon) {
      fetchEvents();
    }
  }, [lat, lon, dateRange]);

  // if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if( !teamsLoading && topteams.length===0) return null;

  const filteredTopTeams = topteams.filter((performer: any) => !performer.name.includes("Rescheduled")).slice(0, 15);
  const filteredImageInfo = filteredTopTeams.slice(0, 15).map(performer => {
    const matchedInfo = imageInfo.find(info => info?.eventId === performer.id);

    return matchedInfo ? matchedInfo : { eventId: performer.id, image_url: sports };
  });

  return (
    <div className=' w-full flex justify-center '>
      {
        (loading) && 
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 bg-white w-screen h-screen">
        <div className='w-full h-full absolute flex items-center justify-center text-black'><LoadingAnimation/></div>
        </div>
      }
      <div className="w-full flex flex-col gap-2 justify-start max-[976px]:px-2 min-[1200px]:w-[1170px] min-[922px]:w-[970px] min-md:w-[750px] max-md:px-[15px] max-md:mx-auto ">
       <div className='flex justify-between'>
       <h1 className={`text-stone-900 md:text-3xl text-2xl font-medium font-oswald uppercase leading-10 ${fredoka.className}`}>TOP TEAMS</h1>
        {/* <div className="flex overflow-x-auto scroll-behavior-smooth mt-4 space-x-4"> */}
        <div className='flex items-center'>
            <Link href={`/see-teams?lat=${lat}&lon=${lon}&dateRange=Any%20Date`}>
            <button className="flex items-center bg-white font-medium text-black px-4 py-2 rounded-md transition-colors duration-300 ease-in-out hover:bg-gray-300 sm:w-auto sm:px-6 sm:py-3" onClick={() => setLoading(true)}>
                <span className="mr-2 hidden md:inline-block">See more teams</span>
                <FiArrowRight className="w-4 h-4" />
              </button>
            </Link>
          </div>
        </div>
        {
          teamsLoading ? (
            <Carousel opts={{ align: "center" }} className="w-full flex justify-center items-center">
              <CarouselContent>
                {[...Array(4)].map((_, index) => (
                  <CarouselItem key={index} className="sm:basis-1/2 md:basis-1/3 lg:basis-1/4">
                    <SkeletonCard />
                  </CarouselItem>
                ))}
              </CarouselContent>
            </Carousel>
          ) : (
            <>

              <Carousel opts={{ align: "start" }} className="w-full">
                <CarouselContent className='flex justify-start items-start'>
                  {filteredTopTeams.map((performer: any, index) => (
                    <CarouselItem key={performer?.id} className="sm:basis-1/2 md:basis-1/3 lg:basis-1/4">
                      <Link href={`/viewtickets1/${performer?.id}`}>
                        <div className={`flex flex-col gap-[8px] ${roboto.className} transition duration-300 hover:shadow-sm hover:scale-[1.02] cursor-pointer`} onClick={() => setLoading(true)}>
                          <div className={`flex flex-col gap-[8px] ${roboto.className}`}>
                            <Image src={filteredImageInfo[index]?.eventId === performer?.id ? filteredImageInfo[index].image_url : sports} alt="event" width={300} height={150} className="rounded-lg shrink-0 object-cover w-full sm:w-[300px] h-[150px]" />
                            <div className={`text-stone-500 text-base font-semibold leading-tight ${fredoka.className}`}>{performer?.name}</div>
                            <div className="text-stone-500 text-base font-normal leading-tight">{formatDate(performer?.occurs_at)}</div>
                            <div className="text-stone-500 text-base font-normal leading-tight">{performer?.venue?.name}</div>
                          </div>
                        </div>
                      </Link>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                {
                  topteams?.length > 4 ? (
                    <>
                      <CarouselPrevious className='hidden sm:block p-6 py-2 pl-2 rounded-full cursor-pointer' />
                      <CarouselNext className='hidden sm:block p-6 py-2 pl-2 rounded-full cursor-pointer' />
                    </>
                  ) : topteams?.length === 4 && (
                    <div className='hidden md:block lg:hidden'>
                      <CarouselPrevious className='hidden sm:block p-6 py-2 pl-2 rounded-full cursor-pointer' />
                      <CarouselNext className='hidden sm:block p-6 py-2 pl-2 rounded-full cursor-pointer' />
                    </div>
                  )
                }
              </Carousel>

            </>
          )
        }

      </div>
    </div>
  )
};
// max-[976px]:px-2 min-[1200px]:w-[1170px] min-[922px]:w-[970px] min-md:w-[750px] max-md:px-[15px] max-md:mx-auto



{/* <div className="flex overflow-x-auto scroll-behavior-smooth justify-start items-start gap-4 p-4">
          {topteams.map((performer: TopTeams, index) => (
            <Link href={`/viewtickets1/${performer.id}`} key={performer.id}>
              <div className={`flex flex-col gap-[8px] ${roboto.className} transition duration-300 hover:shadow-sm hover:scale-[1.02] cursor-pointer w-full ]`} onClick={() => setLoading(true)}>
                <Image
                  src={imageInfo.find(info => info.eventId === performer.id)?.image_url || Teams}
                  alt={performer?.name}
                  width={300}
                  height={150}
                  className="rounded-lg shrink-0 object-cover w-[300px] h-[150px]"
                />
                <div className={`w-full text-stone-500 text-base font-semibold leading-tight ${fredoka.className} break-words w-[300px]`}>
                  {performer?.name}
                </div>
                <div className="text-stone-500 text-base font-normal leading-tight">{formatDate(performer?.occurs_at)}</div>
                <div className="text-stone-500 text-base font-normal leading-tight">{performer?.venue?.name}</div>
              </div>
            </Link>
          ))}
        </div> */}



